@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.section {
	background-color: $shade-white;
}

.banner {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 0;
	width: 100%;
	margin: 0 auto 0;
	
	@include tablet {
		@include contained;
		flex-wrap: nowrap;
		gap: rem(32);
		margin: 0 auto rem(32);
	}

	@include desktop {
		gap: rem(64);
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		order: 2;
		width: 100%;
		gap: rem(28);
		padding: rem(44) rem(32);

		@include tablet {
			width: 50%;
			order: 1;
			padding: rem(32) rem(64);

			& > * {
				max-width: 568px;
			}
		}
	}

	& .title {
		font-size: rem(33);
		line-height: 130%;

		@include tablet {
			font-size: rem(40);
		}
	}

	.regionTitle {
		color: $primary-cardinal-900;
	}

	& .subTitle > * {
		font-weight: 400;
		font-size: rem(23);
		line-height: 150%;

		@include tablet {
			font-size: rem(28);
		}
	}

	& .description {
		font-size: rem(19);
		line-height: 150%;
		color: $primary-midnight-700;

		h5 {
			font-size: rem(16);
			font-weight: 400;
			color: $primary-midnight-700;
		}

		a {
			text-decoration: none;
			color: $secondary-steel-900;
			font-weight: 600;
	
			&:hover,
			&:active {
				text-decoration: underline;
			}
	
			&:focus,
			&:focus-visible {
				outline: rem(2) solid $information-300 !important;
			}
		}
	}

	& .button {
		width: 100%;
		text-align: center;
		font-weight: 400;

		@include mobile-sm {
			width: unset;
			text-align: left;
		}
	}

	& .imageContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		order: 1;
		width: 100%;
		aspect-ratio: 16/10;
		overflow: hidden;
		flex-shrink: 0;

		@include tablet {
			width: 50%;
			order: 2;
			height: 100%;
		}

		&.imageCurveTop {
			clip-path:inset(0 0 0 0 round 128px 0 0 0);
		}
	
		&.imageCurveBottom {
			clip-path:inset(0 0 0 0 round 0 0 128px 0);
		}
	
		&.imageCurveTopBottom {
			clip-path:inset(0 0 0 0 round 128px 0 128px 0);
		}



		& > div {
			height: 100% !important;
			width: 100% !important;
			position: relative;

			& video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			& .playIcon {
				height: rem(50);
				position: absolute;
				color: $primary-cardinal-900;
			}

			& > div {
				position: absolute;
			}

			&:hover .playIcon {
				color: $primary-cardinal-700;
			}
		}

		& img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	& .imageCurveTop {
		border-radius: rem(72) 0 0 0;
	}

	& .imageCurveBottom {
		border-radius: 0 0 rem(72) 0;
	}

	& .imageCurveTopBottom {
		border-radius: rem(72) 0 rem(72) 0;
	}

}
