@import 'abstract/_mixins';
		 @import 'abstract/_variables';
		 @import 'abstract/_functions';
		 @import 'abstract/_typography';
.solutionsWrapper {
	display: grid;
	gap: rem(24);
	padding: rem(96) 0;
	grid-template-columns: 1fr;

	@include tablet {
		@include contained;
		grid-template-columns: repeat(2, minmax(360px, 716px));
	}

	.solution {
		padding: rem(64) rem(32);
		position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 85%;
		}

		&.backgroundColor {
			&::after {
				content: '';
				background-color: #0c436ad9;
			}	
		}

		@include desktop {
			padding: rem(96) rem(72);
		}

		.solutionText {
			z-index: 2;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 0;

			@include desktop {
				padding: 0 rem(64);
			}

			h2 {
				color: $shade-white;
				font-weight: 600;
			}
			p {
				color: $shade-white;
				margin: rem(10) 0 0;
			}
		}
	}
}

.markdown {
	color: $shade-white;
}
